import { filePost } from "@/util/axios";
import { Message } from "element-ui";

export const delTable = (scope, tableData, that, api, callback) => {
  // 删除
  that
    .$confirm("确认删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      let index = null;
      if (scope.$index != undefined) {
        index = scope.$index;
      } else {
        index = scope.scope.$index;
      }

      let obj = {};
      let id = scope.row.id;
      if (name) {
        obj[name] = id;
      } else {
        obj.id = id;
      }
      let add = false;
      if (project) {
        add = true;
      }
    })
    .catch(() => {
      let index = null;
      if (scope.$index != undefined) {
        index = scope.$index;
      } else {
        index = scope.scope.$index;
      }
    });
};
export const del = (
  scope,
  tableData,
  total,
  that,
  api,
  name,
  project,
  callback
) => {
  // 删除
  that
    .$confirm("确认删除?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
    .then(() => {
      let index = null;
      if (scope.$index != undefined) {
        index = scope.$index;
      } else {
        index = scope.scope.$index;
      }
      delFunc(
        scope.row.id,
        index,
        tableData,
        total,
        that,
        api,
        name,
        project,
        callback
      );
    })
    .catch(() => {
      let index = null;
      if (scope.$index != undefined) {
        index = scope.$index;
      } else {
        index = scope.scope.$index;
      }
    });
};
export const delFunc = (
  id,
  index,
  tableData,
  total,
  that,
  api,
  name,
  project,
  callback
) => {
  let obj = {};
  if (name) {
    obj[name] = id;
  } else {
    obj.id = id;
  }
  let add = false;
  if (project) {
    add = true;
  }
  that.$http({
    url: api,
    data: obj,
    add: add,
    done: (res) => {
      that.$message.success("删除成功");
      tableData.splice(index, 1);
      that.total = that.total - 1;
      callback("删除成功");
    },
  });
};

export const exportData = (api, data) => {
  filePost(api, data).then((res) => {
    if (res.data.code == 1) {
      Message.error("还没有数据");
      return;
    }
    //res.data: 文件流数组

    // let _url = "http://192.168.5.199:1028";
    // let _url = "https://api.kejiankeji.com/";
    let _url = "https://kjapi.ehongzhuo.com.cn/";
    const link = document.createElement("a");

    link.target = "_blank";
    link.style.display = "none";
    if (res.data.data.fileZip) {
      link.href = _url + res.data.data.fileZip;
    } else {
      link.href = res.data.data.file;
    }
    link.download = res.data.data.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

// 当前时间
export const dateToStr = () => {
  let date = new Date();
  let year = date.getFullYear(); //年
  let month = date.getMonth(); //月
  let day = date.getDate(); //日
  let hours = date.getHours(); //时
  let min = date.getMinutes(); //分
  let second = date.getSeconds(); //秒
  return (
    year +
    "-" +
    (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
    "-" +
    (day > 9 ? day : "0" + day) +
    " " +
    (hours > 9 ? hours : "0" + hours) +
    ":" +
    (min > 9 ? min : "0" + min) +
    ":" +
    (second > 9 ? second : "0" + second)
  );
};
// 找到指定的路径
export const fingPath = (curKey, data) => {
  let result = []; // 记录路径结果
  let traverse = (curKey, path, data) => {
    if (data.length === 0) {
      return;
    }
    for (let item of data) {
      path.push(item);
      if (item.name.indexOf(curKey) != -1) {
        result = JSON.parse(JSON.stringify(path));
        return;
      }
      const children = Array.isArray(item.children) ? item.children : [];
      traverse(curKey, path, children); // 遍历
      path.pop(); // 回溯
    }
  };
  traverse(curKey, [], data);
  return result;
};
export const fingPath2 = (curKey, data) => {
  let result = []; // 记录路径结果
  let traverse = (curKey, path, data) => {
    if (data.length === 0) {
      return;
    }
    for (let item of data) {
      path.push(item);

      if (item.name === curKey) {
        result = JSON.parse(JSON.stringify(path));
        return;
      }
      const children = Array.isArray(item.children) ? item.children : [];
      traverse(curKey, path, children); // 遍历
      path.pop(); // 回溯
    }
  };
  traverse(curKey, [], data);
  return result;
};
export const fingPath3 = (curKey, data) => {
  let result = []; // 记录路径结果
  let traverse = (curKey, path, data) => {
    if (data.length === 0) {
      return;
    }
    for (let item of data) {
      path.push(item);

      if (item.path === curKey) {
        result = JSON.parse(JSON.stringify(path));
        return;
      }
      const children = Array.isArray(item.children) ? item.children : [];
      traverse(curKey, path, children); // 遍历
      path.pop(); // 回溯
    }
  };
  traverse(curKey, [], data);
  return result;
};
export const ruleShow = (code) => {
  let ruleArr = JSON.parse(sessionStorage.getItem("rule"));
  console.log("返回数据", code);
};
