<template>
    <div class="header ">
        <div class="nav  navfree" :class="{navfree2: $route.name == 'frontintro'||$route.name == 'frontIndex'}">
            <div class="nav2">
                <img src="@/assets/logo6.png" v-if=" $route.name == 'frontintro'||$route.name == 'frontIndex'" />
                <img src="@/assets/logo7.png" v-else />
                <!-- <span class="iconfont xm_shixiang"></span> -->
                <span v-for="(item, index) in nav" :class="{active: active==index}" :key="index"
                    @click="goNav(item, index)">{{item.name}}</span>
                <span v-if="!user.id">
                    <a @click="goLogin" class="btn">登录</a>
                    <a @click="goregister" style='margin-left:10px' class="btn">注册</a>
                </span>

                <span v-else>
                    <a class="btn" @click="goBackstage">进入系统</a>
                    <a class="btn" @click="EiteUser" style="margin-left:5px;opacity: .7;">退出</a>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        consult,
        consultOther,
        serviceMagforConsult,
        labor,
        personMag,
        platMag,
        project,
        allMag,
        supplier,
        doer,
    } from "@/components/data.js";
    export default {
        data() {
            return {
                nav: [
                    { path: '/', name: '数字化管理平台' },
                    { path: '/front/hire', name: '工程招聘' },
                    { path: '/front/material', name: '材料设备' },
                    { path: '/front/machine', name: '工程机械' }, { path: '/front/business', name: '业务大厅' },
                    { path: '/front/service', name: '三方咨询' }, { path: '/front/about', name: '关于我们' }
                ],
                active: -1,
                user: ''
            }
        },
        mounted() {
            // { path: '/front/hire', name: '工程招聘' },
            this.user = ''
            this.user = JSON.parse(sessionStorage.getItem('userInfo')) || ''
            // { path: '/', name: '首页' },
        },
        methods: {
            goLogin() {
                this.$router.push({ path: '/login' })
            },
            goregister() {
                this.$router.push({ path: '/sign' })
            },
            EiteUser() {
                sessionStorage.removeItem('projectInfo')
                sessionStorage.removeItem('token')
                sessionStorage.removeItem("tokenOrder");
                sessionStorage.removeItem('userInfo')
                this.user = ''
            },
            async goBackstage() {

                if (!sessionStorage.getItem('token')) {
                    this.$router.push({ path: '/login' });
                    return
                }
                let forbread = JSON.parse(sessionStorage.getItem('forbread'));
                // let nav = sessionStorage.getItem("navs") || '';
                // let menuList = await this.getMenu();

                // if (nav.indexOf("项目总包") > -1) {
                //     this.navs = allMag;
                // } else if (nav.indexOf("施工") > -1) {
                //     this.navs = doer;
                // } else if (nav.indexOf("劳务公司") > -1) {
                //     this.navs = labor;
                // } else if (nav.indexOf("咨询公司") > -1) {
                //     this.navs = consult;
                // } else if (nav.indexOf("其他咨询公司") > -1) {
                //     this.navs = consultOther;
                // } else if (nav.indexOf("项目") > -1) {
                //     this.navs = menuList;
                // } else if (nav.indexOf("供应商") > -1) {
                //     this.navs = supplier;
                // } else if (nav.indexOf("个人") > -1) {
                //     this.navs = personMag;
                // } else if (nav.indexOf("平台") > -1) {
                //     this.navs = platMag;
                // } else {
                //     this.$router.push({ path: '/login' });
                //     return
                // }
                sessionStorage.setItem("changeNav", null);
                sessionStorage.setItem("forbread", JSON.stringify(forbread));
                // let path = this.findFirstPath(this.navs)
                this.$router.push({ path: forbread[0].path });
            },
            findFirstPath(consultOther) {
                for (let i = 0; i < consultOther.length; i++) {
                    const item = consultOther[i];

                    if (item.children.length > 0) {
                        const path = this.findFirstPath(item.children);
                        if (path) { return path; }
                    } else {
                        return item.path;
                    }
                } return null;
            },
            getMenu() {
                let nav = sessionStorage.getItem("navs");
                let type = ''
                if (nav.indexOf("项目总包") > -1) {
                    type = 1;
                } else if (nav.indexOf("施工") > -1) {
                    type = 2
                } else if (nav.indexOf("劳务公司") > -1) {
                    type = 4
                } else if (nav.indexOf("咨询公司") > -1) {
                    type = 5
                } else if (nav.indexOf("其他咨询公司") > -1) {
                    type = 6
                } else if (nav.indexOf("项目") > -1) {
                    type = 0
                } else if (nav.indexOf("供应商") > -1) {
                    type = 3
                } else if (nav.indexOf("个人") > -1) {
                    type = 7
                } else if (nav.indexOf("平台") > -1) {
                    type = 8
                }
                return new Promise((resolve, reject) => {
                    this.$http({
                        url: "/api/admin.project.survey/getMenu",
                        data: {
                            user_type: type
                        },
                        done: (item) => {
                            let newMenu = []
                            newMenu = this.setChecked(item)
                            resolve(newMenu);
                        },
                    });
                });
            },
            setChecked(tree) {
                tree.forEach(item => {
                    item.name = item.menu_name;
                    item.path = item.menu_url;
                    // this.$forceUpdate()
                    if (item.children) {
                        this.setChecked(item.children);
                    }
                });
                return tree;
            },
            goSign() {
                this.$router.push({ path: '/sign' })
            },
            goNav(item, index) {
                this.active = index
                this.$router.push({ path: item.path })
            }
        },
        created() {

            // console.log(this.user,'777')
        },
        destroyed() {
            // this.EiteUser()
        }

    }
</script>
<style lang="less" scoped>
    .header {
        min-width: 68.75rem;
        position: fixed;
        width: 100%;
        z-index: 1999;
        height: 5rem;
    }

    .nav {
        display: flex;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 0rem .125rem .625rem 0rem rgba(0, 0, 0, 0.08);
        padding: 1.25rem 1.25rem;
        font-size: .9375rem;
        margin: auto;

        img {
            width: 10.625rem;
            height: 2.8125rem;
            margin-right: 7.5rem;
        }
    }

    .nav span {
        display: inline-block;
        margin-right: 3.6875rem;
        color: #262626;
        line-height: 2.1875rem;
        font-family: Source Han Sans CN;
        cursor: pointer;
        font-size: 1.1875rem;
    }

    .userClass {
        color: #4E90FF !important;
        padding-left: .625rem;
        position: relative;
        top: .0625rem;
    }

    .nav .active {
        color: #224682;
    }

    .nav .btn {
        display: inline-block;
        background: linear-gradient(75deg, #5192FF 0%, #1B70FF 100%);
        color: white;
        font-size: .75rem;
        border-radius: .625rem;
        line-height: 1.875rem;
        height: 1.875rem;
        padding: 0 .625rem;
        margin-top: .125rem;
    }

    .navfree {
        box-shadow: 0rem .1875rem .75rem 0rem rgb(0 0 0 / 5%);
        background: rgba(255, 255, 255, 0.7);
    }

    .navfree2 {
        background: rgba(46, 52, 64, 0.26);
        box-shadow: 0rem .1875rem .75rem 0rem rgba(0, 0, 0, 0.05);

        span {
            color: white;
            font-size: 1.1875rem;
        }
    }

    .nav2 {
        display: flex;
        align-items: center;
        width: 1600px;
    }
</style>