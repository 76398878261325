<template>
    <div class=" p-20 flex bg-white mb-20" id="count">
        <div v-for="(item, index) in Object.keys(list)" :key="index" class="list"
            :style="{display: labels[index]==undefined ? 'none' : ''}">
            <span class="bl">{{labels[index]}}</span>
            <span class="num">{{getNumber(list[item],labels[index])}} </span>
            <!-- {{getNumber(list[item])}} -->
        </div>
        <slot name="slot"></slot>
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            labels: {
                type: Array,
                default: () => []
            },
            list: {
                type: Object,
                default: () => { }
            }
        },
        mounted() {
            // this.getNumber()
        },
        methods: {
            getNumber(data, title) {

                if ((data + '').indexOf('.') != -1) {
                    const money = data + "";
                    const temp = money.split('.');

                    let val = temp[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,') + '.' + (temp[1].length >= 2 ? temp[1] : temp[1] + '0' || '00');
                    return val
                }
                if (title.indexOf('元') != -1) {
                    return (data + '').replace(/(\d)(?=(\d{3})+$)/g, '$1,') + '.' + '00';
                }
                return (data + '').replace(/(\d)(?=(\d{3})+$)/g, '$1,')
            }
        }
    }
</script>
<style lang="less" scoped>
    .flex {
        display: flex;
        overflow: auto;
    }

    .list {
        background: #F6F6F6;
        margin-right: 20px;

        max-width: 300px;
        padding: 0.8rem 0.9rem;
        position: relative;

        .bl {
            color: #999;
            display: block;
            margin-bottom: 10px;
            font-size: 0.8rem;
        }

        .num {
            color: #409EFF;
            font-size: 1.5rem;
        }

        .des {
            font-size: 12px;
            display: inline-block;
            transform: scale(0.9);
            position: absolute;
            bottom: 16px;
        }
    }
</style>