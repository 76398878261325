export default [
  {
    path: "/personMag/performance",
    name: "performance",
    component: () => import("@/views/personMag/me/performance"),
  },
  {
    path: "/personMag/info",
    component: () => import("@/views/personMag/info"),
    name: "personMagInfo",
  },
  {
    path: "/personMag/account",
    component: () => import("@/views/personMag/me/account"),
    name: "personMagaccount",
  },
  {
    path: "/personMag/index",
    component: () => import("@/views/personMag/index"),
    name: "personMagIndex",
  },
  {
    path: "/personMag/list",
    component: () => import("@/views/personMag/list"),
    name: "personMagList",
  },
  {
    path: "/personMag/agency",
    component: () => import("@/views/personMag/agency"),
    name: "personMagAgency",
  },
  {
    path: "/personMag/list2",
    component: () => import("@/views/personMag/list2"),
    name: "personMagList2",
  },
];
