import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { dateToStr } from "./util/common.js";

import "./css/font/iconfont.css";

Vue.use(ElementUI);
import less from "less";
Vue.use(less);
import axios from "axios";
Vue.prototype.$axios = axios;
import utils from "@/util/utils.js";
Vue.prototype.$bus = new Vue();
require("../mock/index");
Vue.prototype.$prise_id = JSON.parse(
  sessionStorage.getItem("userInfo")
)?.prise_id;
Vue.prototype.$alertStatus = {};
Vue.prototype.$user_id = JSON.parse(sessionStorage.getItem("userInfo"))?.id;
// Vue.prototype.$url = "http://192.168.5.198:1028";
//https://api.kejiankeji.com/
// Vue.prototype.$url = "https://api.kejiankeji.com/";
Vue.prototype.$url = "https://kjapi.ehongzhuo.com.cn/";
// console.log(dateToStr,'4444')
Vue.prototype.$dateToStr = dateToStr;
Vue.prototype.$utils = utils;

Vue.config.productionTip = false;
import { GetData } from "./util/axios";
Vue.prototype.$http = function (opt) {
  GetData(opt, this);
};
import search from "@/components/search";
Vue.component("search", search);

import count from "@/components/count";
Vue.component("count", count);

import forms from "@/components/form";
Vue.component("forms", forms);

import uploads from "@/components/uploadFile";
Vue.component("uploads", uploads);

// import UploadImg from "@/components/UploadImg";
// Vue.component("uploads", UploadImg);

import tables from "./components/table.vue";
Vue.component("tables", tables);

import quillmyeditor from "./components/quillmyeditor.vue";
Vue.component("quillmyeditor", quillmyeditor);
import modal from "./components/modal.vue";
Vue.component("modal", modal);

Vue.directive("filterSpecialChar", {
  update: function (el, { value, modifiers }, vnode) {
    try {
      let a_el = el.children[0];

      if (!a_el.value) {
        return false;
      }
      a_el.value = a_el.value.replace(/[^a-zA-Z0-9\u4E00-\u9FA5#@%]/g, "");
      a_el.dispatchEvent(new Event(modifiers.lazy ? "change" : "input"));
    } catch (e) {}
  },
});

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");
