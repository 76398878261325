import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import project from "./modules/project.js";
import allMag from "./modules/all.js";
import doer from "./modules/doer.js";
import common from "./modules/common.js";
import supplier from "./modules/supplier.js";
import labor from "./modules/labor.js";
import consult from "./modules/consult.js";
import personMag from "./modules/personMag.js";
import platMag from "./modules/platMag.js";
import front from "./modules/front.js";
// import datav from './modules/datav.js'
// import goods from './modules/goods.js'
// const Login = () => import(/* webpackChunkName: "login" */'@/views/login')
// const JikeLogin = () => import(/* webpackChunkName: "jikeLogin" */'@/views/jikeLogin')

const routes = [
  ...project,
  ...allMag,
  ...doer,
  ...doer,
  ...supplier,
  ...labor,
  ...common,
  ...consult,
  ...personMag,
  ...platMag,
  ...front,
  {
    path: "/main",
    component: () => import("@/views/main"),
    name: "main",
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    name: "login",
  },
  {
    path: "/sign",
    component: () => import("@/views/sign/index"),
    name: "sign",
  },
];
const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  // 保存页面参数，刷新或返回页面时使用
  const hasRealParams = to.params && Object.keys(to.params).length;
  if (hasRealParams) {
    window.sessionStorage.setItem(to.name, JSON.stringify(to.params));
  }
  next();
});

// 屏蔽router push相同url时console报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
