export default [
  {
    path: "/common/hire",
    name: "commonHire",
    component: () => import("@/views/common/hire"),
  },
  {
    path: "/common/hire2",
    name: "commonHire2",
    component: () => import("@/views/common/hire2"),
  },
  {
    path: "/common/adviser",
    component: () => import("@/views/common/advice/adviser"),
    name: "adviser",
  },
  {
    path: "/common/service",
    component: () => import("@/views/common/advice/service"),
    name: "service",
  },
  {
    path: "/common/myadvice",
    component: () => import("@/views/common/advice/myadvice"),
    name: "myadvice",
  },
  {
    path: "/common/myadvice2",
    component: () => import("@/views/common/advice/myadvice2"),
    name: "myadvice2",
  },
  {
    path: "/common/platformPush",
    component: () => import("@/views/common/platformPush"),
    name: "myadvice",
  },
  {
    path: "/common/platformPush2",
    component: () => import("@/views/common/platformPush2"),
    name: "platformPush2",
  },
  {
    path: "/common/push",
    name: "commonPush",
    component: () => import("@/views/common/push"),
  },
  {
    path: "/common/company/doc",
    name: "commonDoc",
    component: () => import("@/views/common/company/doc"),
  },
  {
    path: "/common/company/account",
    name: "commonAccount",
    component: () => import("@/views/common/company/account"),
  },
  {
    path: "/common/company/mag",
    name: "commonMag",
    component: () => import("@/views/common/company/mag"),
  },
  {
    path: "/common/company/achievment",
    name: "commonMag",
    component: () => import("@/views/common/company/achievment"),
  },
  {
    path: "/common/myfind",
    name: "myfind",
    component: () => import("@/views/common/advice/myfind"),
  },
  {
    path: "/common/engineer",
    name: "engineer",
    component: () => import("@/views/common/advice/engineer"),
  },
  {
    path: "/common/agency",
    name: "agency",
    component: () => import("@/views/common/advice/agency"),
  },
  {
    path: "/common/auth",
    name: "auth",
    component: () => import("@/views/common/auth"),
  },
];
