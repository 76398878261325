// import api from '@/utils/apiRing'
const consult = {
  state: {
    connectProject: {},
    isSign: false,
  },

  mutations: {
    // 跳入服务管理
    changeNav: (state, val) => {
      state.connectProject = val;
    },
    alreadySign: (state, val) => {
      state.isSign = val;
    },
  },
  actions: {},
};

export default consult;
