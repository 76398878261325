<template>
  <div id="app">
    <div v-if="valid" class="main">
      <navs></navs>
      <div class="content">
        <com-head></com-head>
        <div style="padding: 20px; min-width: 900px; box-sizing: border-box">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div v-else-if="this.$route.name == 'platMagLogin'||this.$route.name == 'leaveMsg'">
      <router-view></router-view>
    </div>
    <div v-else>
      <div class="top-uni">
        <top></top>
      </div>
      <router-view></router-view>
      <foot v-if="$route.name != 'frontintro' && $route.name != 'frontIndex'"></foot>
    </div>
  </div>
</template>
<script>
  import comHead from "./components/comHead";
  import top from "./components/header";
  import foot from "./components/footer";
  import navs from "./components/nav";
  import {
    consult,
    serviceMagforConsult,
    labor,
    personMag,
    platMag,
    project,
    allMag,
    supplier,
    doer,
  } from "./components/data.js";
  export default {
    name: "App",
    data() {
      return {
        isLogin: sessionStorage.getItem("userInfo"),
        isConsult: sessionStorage.getItem("consultNav"),
        nav: serviceMagforConsult,
        consult: consult,
      };
    },
    computed: {
      valid() {
        console.log(this.$route.name, 7987897)
        return (
          this.$route.name &&
          this.$route.name !== "platMagLogin" &&
          this.$route.name !== "leaveMsg" &&
          this.$route.name !== "login" &&
          this.$route.name !== "sign" &&
          this.$route.name.indexOf("front") == -1
        );
      },
    },
    mounted() { },
    methods: {},
    components: {
      top,
      foot,
      navs,
      comHead,
    },
  };
</script>
<style lang="less">
  @import "./css/index.less";
  @import "./css/reset.less";
</style>
<style lang="less">
  body {
    margin: 0;
  }


  #app {
    background: #f7f7f7;
    min-height: 100vh;
  }

  .top-uni {
    height: 80px;
  }

  .main {
    display: flex;

    .content {
      flex: 1;
      min-width: 1100px;
    }

    .menu {
      background: #1f2533;
      color: white;
      min-height: 100vh;
      min-width: 257px;
    }

    /deep/.el-message {
      z-index: 10000000;
      /* 设置z-index为10000，可以根据需求自行调整 */
    }

    .el-menu-vertical-demo {
      width: 240px;
      height: 89.4vh;
      overflow: auto;
      border: none;
    }

    .el-menu-vertical-demo {
      ::-webkit-scrollbar {
        background-color: #000;
      }

      ::-webkit-scrollbar-track {
        background-color: #7dc2f6;
      }

      ::-webkit-scrollbar-button {
        background-color: #b9e0fd;
      }

      ::-webkit-scrollbar-corner {
        background-color: #018ef8;
      }
    }

    .el-menu-vertical-demo::-webkit-scrollbar-track {
      background-color: #333;
    }

    .el-menu-vertical-demo::-webkit-scrollbar-thumb {
      background-color: #333;
      opacity: 0.5;
      border: none;
    }
  }
</style>