<template>
  <div class="flex">
    <el-upload class="avatar-uploader" :action="url + '/api/admin.upload/upload'" :show-file-list="false"
      :auto-upload="false" v-loading="loading" :on-change="handleAvatarSuccess" :before-upload="beforeUpload">
      <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <div class="imgs">
      <div v-for="(item, index) in imgs" class="ml-20" :key="index" :style="{
          display:
            item.type == 'image' || item.type == 'video' ? 'inline-block' : '',
        }">
        <!-- <span>{{item.url}}</span> -->

        <img :src="item.url" v-if="item.type == 'image'" />
        <video :src="item.url" v-else-if="item.type == 'video'">-1"></video>
        <a v-else>{{ item.url }}</a>
        <i class="el-icon-circle-close cur" @click="del(item, index)"></i>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        imageUrl: "",
        url: window.location.origin,
        imgs: [],
        loading: false,
      };
    },
    props: {
      close: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default: () => { },
      },
      list: {
        type: Array,
        default: () => [],
      },
    },
    watch: {
      close(val) {

        if (val) {
          this.imgs = [];
        }
      },
      list(val) {

        if (this.imgs.length > 0 && this.imgs[0].url == "") this.imgs = [];
        if (val.length > 0 && this.imgs.length == 0) {
          this.imgs = [];
          val.forEach((item) => {
            let obj = {
              name: this.item.field,
              url: "",
            };
            if (
              item.indexOf("png") > -1 ||
              item.indexOf("jpg") > -1 ||
              item.indexOf("jpeg") > -1 ||
              item.indexOf("gif") > -1
            ) {
              obj.type = "image";
              obj.url = this.$url + item;
              this.imgs.push(obj);
            } else if (item.indexOf("mp4") > -1) {
              obj.type = "video";
              obj.url = this.$url + item;
              this.imgs.push(obj);
            } else {
              obj.url = item;
              this.imgs.push(obj);
            }
          });

        } else {
        }
      },
    },
    mounted() {
      setTimeout(() => {

        if (this.list.length > 0 && this.imgs.length == 0) {
          this.list.forEach((item) => {
            let obj = {
              name: this.item.field,
              url: "",
            };
            if (
              item.indexOf("png") > -1 ||
              item.indexOf("jpg") > -1 ||
              item.indexOf("jpeg") > -1 ||
              item.indexOf("gif") > -1
            ) {
              obj.type = "image";
              obj.url = this.$url + item;
              this.imgs.push(obj);
            } else if (item.indexOf("mp4") > -1) {
              obj.type = "video";
              obj.url = this.$url + item;
              this.imgs.push(obj);
            } else {
              obj.url = item;
              this.imgs.push(obj);
            }
          });
        }

      }, 1000);
    },
    methods: {
      beforeUpload(file) {

        const isJPG = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type)
        const isLtSize = file.size / 1024 < 500
        if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'].indexOf(file.type) === -1) {
          this.$message.error('请上传正确的视频格式')
          return false
        }

        if (!isJPG) {
          this.$message.error('文件非 JPG/PNG/JPEG/GIF 格式')
          return false
        }

        return true
      },
      del(item, index) {
        this.imgs.splice(index, 1);
        item.index = index;
        this.$emit("fileDel", item);
      },
      handleAvatarSuccess(file) {

        var FileExt = file.name.replace(/.+\./, "");
        let f1 = ['png', 'jpg', 'jpeg', 'gif'].indexOf(FileExt.toLowerCase()) === -1

        let f2 = ['mp4'].indexOf(FileExt.toLowerCase()) === -1

        if (f1 && f2) {
          this.$message({
            type: "warning",
            message: "请上传.png .jpg .jpeg,格式或者mp4视频的文件"
          });
          return false;
        }

        let formData = new FormData();
        formData.append("file_name", file.raw);
        this.loading = true;
        this.$http({
          url: "/api/admin.upload/upload",
          data: formData,
          header: true,
          done: (res) => {
            this.loading = false;
            if (file.raw.type.indexOf("image") > -1) {
              this.imgs.push({
                url: URL.createObjectURL(file.raw),
                name: this.item.field,
                type: "image",
              });
            } else if (file.raw.type.indexOf("video") > -1) {
              this.imgs.push({
                url: URL.createObjectURL(file.raw),
                name: this.item.field,
                type: "video",
              });
            } else {
              this.imgs.push({ url: file.name, name: this.item.field });
            }
            this.$emit("fileurl", {
              res: res,
              name: this.item.field,
              type: this.item.type2,
              title: file.raw.name,
            });
          },
        });
      },
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 50;

        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 50MB!");
        }
        return isLt2M;
      },
    },
  };
</script>

<style lang="less" scoped>
  /deep/ .el-form-item__content {
    line-height: normal;
  }

  video {
    width: 88px;
    height: 84px;
  }

  .imgs {
    line-height: 30px;
  }

  .imgs div {
    position: relative;

    span {
      display: inline-block;
      width: 160px;
      height: 33px;
      overflow: hidden;
    }

    img {
      width: 88px;
      height: 84px;
    }

    i {
      position: absolute;
      right: -18px;
      color: #409eff;
      font-size: 18px;
      top: 2px;
    }
  }
</style>
<style>
  .avatar {
    width: 80px;
    height: 80px;
  }

  .avatar-uploader .el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #e5e5e5;
    width: 83px;
    height: 83px;
    line-height: 83px;
    font-size: 40px;
    text-align: center;
  }
</style>