// 获取中国时区时间
export let isConsult = (item) => {
  let user = JSON.parse(sessionStorage.getItem("userInfo"));
  // if(item.userid==user.id){

  // }else{
  // 	return false
  // }
  return false;
};
export const ruleShow = (code) => {
  let ruleArr = JSON.parse(sessionStorage.getItem("rule"));
  let user = JSON.parse(sessionStorage.getItem("userInfo"));
  if (user.position_name == "平台管理员") {
    return true;
  }
  if (ruleArr.length == 0) {
    return true;
  }
  for (let item of ruleArr) {
    if (item == code) {
      return true;
    }
  }
  return false;
};
export const ruleShow2 = (code) => {
  let user = JSON.parse(sessionStorage.getItem("userInfo"));
  if (user.position_name == "平台管理员") {
    return true;
  }
  let arr = code.split("-");
  let ruleArr = JSON.parse(sessionStorage.getItem("rule"));
  if (ruleArr.length == 0) {
    return true;
  }
  for (let item of ruleArr) {
    if (arr.indexOf(item) != -1) {
      return true;
    }
  }
  return false;
};
export const goConsu = () => {
  let is_pay_advice = "";
  let user = JSON.parse(sessionStorage.getItem("userInfo"));
  let navs = sessionStorage.getItem("navs");
  if (navs == "项目") {
    is_pay_advice = user.project.is_pay_advice;
  } else if (navs == "劳务公司" || navs == "施工单位" || navs == "供应商") {
    is_pay_advice = user.prise.is_pay_advice;
  } else if (navs == "个人" || navs == "班组长" || navs == "项目总包") {
    is_pay_advice = user.is_pay_advice;
  }

  if (is_pay_advice == 1) {
    return true;
  }

  return false;
};
export const paySuccess = () => {
  let navs = sessionStorage.getItem("navs");
  let user = JSON.parse(sessionStorage.getItem("userInfo"));
  if (navs == "项目") {
    user.project.is_pay_advice = 1;
    sessionStorage.setItem("userInfo", JSON.stringify(user));
  } else if (navs == "劳务公司" || navs == "施工单位" || navs == "供应商") {
    user.prise.is_pay_advice = 1;
    sessionStorage.setItem("userInfo", JSON.stringify(user));
  } else if (navs == "个人" || navs == "班组长" || navs == "项目总包") {
    user.is_pay_advice = 1;
    sessionStorage.setItem("userInfo", JSON.stringify(user));
  }
};
export default {
  isConsult,
  ruleShow,
  goConsu,
  paySuccess,
  ruleShow2,
};
