<template>
  <div class="p-20 bg-white mb-20 search-block flex" id="search">
    <div class="left">
      <span v-for="(item, index) in list.filter((item) => item.name != '')" :key="index" class="span">
        <span class="label fs-13 com-c" v-if="item.type !== 'check'">{{
          item.name
          }}</span>
        <addressd v-if="item.type === 'address'" :item="item" @getadd="getAddress">
        </addressd>
        <el-select v-model="form[item.prop]" v-else-if="item.type === 'select'" clearable>
          <el-option :value="item.value" v-for="(item, index) in item.options" :label="item.label" :key="index">
          </el-option>
        </el-select>
        <el-checkbox label v-else-if="item.type === 'check'" v-model="form[item.prop]">{{ item.name }}</el-checkbox>
        <el-date-picker clearable :picker-options="pickerOptions3" v-model="form[item.prop]"
          v-else-if="item.type == 'datetime'" type="daterange" class="mr-5" style="width: 224px"
          value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <el-date-picker v-model="form[item.prop]" v-else-if="item.type == 'date'" value-format="yyyy-MM-dd"
          format="yyyy-MM-dd" placeholder="请选择" type="date" style="width: 180px"></el-date-picker>
        <el-input clearable :placeholder="'请输入'+item.name" v-model="form[item.prop]" v-else-if="item.name != ''" />
        <el-input type="number" clearable v-else-if="item.type === 'number'" />
      </span>
      <slot name="slot" :form="form"></slot>
    </div>
    <el-button @click="search" class="btn" type="primary" size="small">查询</el-button>
    <slot name="btn" :form="form"></slot>
  </div>
</template>
<script>
  import addressd from "./address";
  export default {
    props: {
      list: {
        type: Array,
        default: () => [],
      },
    },
    components: {
      addressd,
    },
    data() {
      return {
        date: [],
        form: {},
        endTime: JSON.parse(sessionStorage.getItem('projectInfo'))?.finish_date_totlal,
        startTime: JSON.parse(sessionStorage.getItem('projectInfo'))?.ment_date,
        pickerOptions3: {
          disabledDate: (time) => {
            return (
              time.getTime() <= new Date(this.startTime).getTime() - 8.64e7 || time.getTime() >= new Date(this.endTime).getTime()
            );
          }
        }
      };
    },
    methods: {
      getAddress(e) {
        this.form[e.name] = e.addr;
      },
      search() {
        let e = JSON.parse(JSON.stringify(this.form))
        this.$emit("info", e);
      },
    },
  };
</script>
<style scoped lang="less">
  .label {
    display: inline-block;
    margin-right: 8px;

  }

  .left {
    max-width: 93%;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
  }

  .btn {
    height: 35px;
    margin-top: 5px;
  }

  .span {
    display: inline-block;
    // min-width: 25%;
    margin-bottom: 5px;
    margin-top: 5px;

    min-width: 270px;
  }

  /deep/ .el-input__icon {
    line-height: 36px;
  }

  /deep/ .search-block {
    .money.el-input {
      margin-right: 10px;
      height: 30px;
      padding: 10px;
    }
  }
</style>