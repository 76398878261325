export default [
  // 项目总包
  {
    path: "/all/index",
    name: "meInfo",
    component: () => import("@/views/allMag/index"),
  },
  {
    path: "/all/me/info",
    name: "meInfo",
    component: () => import("@/views/allMag/me/info"),
  },
  {
    path: "/all/me/mag",
    name: "meMag",
    component: () => import("@/views/allMag/me/mag"),
  },
  {
    path: "/all/supplier/labor",
    name: "supplierLabor",
    component: () => import("@/views/allMag/supplier/labor"),
  },
  {
    path: "/all/supplier/machine",
    name: "supplierMachine",
    component: () => import("@/views/allMag/supplier/machine"),
  },
  {
    path: "/all/supplier/stuff",
    name: "supplierStuff",
    component: () => import("@/views/allMag/supplier/stuff"),
  },
  {
    path: "/all/list",
    name: "allList",
    component: () => import("@/views/allMag/list"),
  },
  {
    path: "/allMag/platformPush",
    name: "platformPush",
    component: () => import("@/views/allMag/platformPush"),
  },

  {
    path: "/allMag/push",
    name: "allpush",
    component: () => import("@/views/allMag/push"),
  },
];
