import { render, staticRenderFns } from "./comHead.vue?vue&type=template&id=d2dcd688&scoped=true&"
import script from "./comHead.vue?vue&type=script&lang=js&"
export * from "./comHead.vue?vue&type=script&lang=js&"
import style0 from "./comHead.vue?vue&type=style&index=0&id=d2dcd688&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2dcd688",
  null
  
)

export default component.exports