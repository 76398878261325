export default [
  {
    path: "/front/about",
    name: "frontAbout",
    component: () => import("@/views/front/about"),
  },
  {
    path: "/",
    component: () => import("@/views/front/intro"),
    name: "frontintro",
  },
  // {
  //     path: '/',
  //     name: 'frontIndex',
  //     component: () =>
  //         import ('@/views/front/index')
  // },
  {
    path: "/front/hire",
    name: "frontHire",
    component: () => import("@/views/front/hire"),
  },
  {
    path: "/front/fee",
    component: () => import("@/views/front/fee"),
    name: "frontfee",
  },
  //   {
  //     path: "/front/intro",
  //     component: () => import("@/views/front/intro"),
  //     name: "frontintro",
  //   },
  {
    path: "/front/business",
    component: () => import("@/views/front/business"),
    name: "frontbusiness",
  },
  {
    path: "/front/leaveMsg",
    component: () => import("@/views/front/leaveMsg"),
    name: "leaveMsg",
  },
  {
    path: "/front/machine",
    component: () => import("@/views/front/machine"),
    name: "frontmachine",
  },
  {
    path: "/front/material",
    component: () => import("@/views/front/material"),
    name: "frontmaterial",
  },
  {
    path: "/front/service",
    component: () => import("@/views/front/service"),
    name: "frontservice",
  },
  {
    path: "/front/services/tech",
    component: () => import("@/views/front/services/tech"),
    name: "frontservice",
  },
  {
    path: "/front/services/cost",
    component: () => import("@/views/front/services/cost"),
    name: "frontcost",
  },
  {
    path: "/front/services/law",
    component: () => import("@/views/front/services/law"),
    name: "frontlaw",
  },
  {
    path: "/front/services/tax",
    component: () => import("@/views/front/services/tax"),
    name: "fronttax",
  },
  {
    path: "/front/services/plat",
    component: () => import("@/views/front/services/plat"),
    name: "frontplat",
  },
  {
    path: "/front/orderDetails/recHire",
    component: () => import("@/views/front/orderDetails/recHire"),
    name: "frontrecHire",
  },
  {
    path: "/front/orderDetails/hunHire",
    component: () => import("@/views/front/orderDetails/hunHire"),
    name: "fronthunHire",
  },
  {
    path: "/front/orderDetails/laborHire",
    component: () => import("@/views/front/orderDetails/laborHire"),
    name: "frontlaborHire",
  },
  {
    path: "/front/orderDetails/supHire",
    component: () => import("@/views/front/orderDetails/supHire"),
    name: "frontsupHire",
  },
  {
    path: "/front/orderDetails/supMaterial",
    component: () => import("@/views/front/orderDetails/supMaterial"),
    name: "frontsupMaterial",
  },
  {
    path: "/front/orderDetails/materialListDetails",
    component: () => import("@/views/front/orderDetails/materialListDetails"),
    name: "frontmaterialListDetails",
  },
  {
    path: "/front/orderDetails/purMaterial",
    component: () => import("@/views/front/orderDetails/purMaterial"),
    name: "frontpurMaterial",
  },

  {
    path: "/front/orderDetails/supMachine",
    component: () => import("@/views/front/orderDetails/supMachine"),
    name: "frontsupsupMachine",
  },
  {
    path: "/front/orderDetails/purMachine",
    component: () => import("@/views/front/orderDetails/purMachine"),
    name: "frontpupurMachine",
  },

  {
    path: "/front/services/doc",
    component: () => import("@/views/front/details/doc"),
    name: "frontdoc",
  },
  {
    path: "/front/services/detail",
    component: () => import("@/views/front/details/service"),
    name: "frontdoc",
  },
  {
    path: "/front/services/detail2",
    component: () => import("@/views/front/details/service2"),
    name: "frontdoc",
  },
  {
    path: "/front/frontfee2",
    component: () => import("@/views/front/frontfee2"),
    name: "frontfee2",
  },
];
