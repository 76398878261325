<template>
    <div class="footer" :class="{'otherfoot': $route.name=='login'}">
        <div>
            <!-- {{info.company_name}} {{info.filing_no}} -->
            © {{year || '2023'}} {{info.company_name}} &nbsp; 邮编:{{info.postal_code}} &nbsp; {{info.email}}
        </div>
        <div>
            {{info.address}}
        </div>
        <div @click.stop='tomit'>
            <!-- 版权所有{{info.filing_address}} -->
            备案号:{{info.filing_no}}
        </div>
        <div>
            建议您使用Chrome、Edge和360等主流浏览器浏览本网站
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                info: {

                },
                year: "2023"
            }
        },
        created() {
            this.$http({
                url: "api/index.index/getIndexInfo",
                data: {},
                add: true,
                done: (res) => {

                    this.info = res.contact;
                },
            });
        },
        mounted() {
            this.getYear()
        },
        methods: {
            tomit() {
                window.open('https://beian.miit.gov.cn/', '_blank');
            },
            getYear() {
                let nowDate = new Date();
                let date = {

                    // 获取当前年份
                    year: nowDate.getFullYear(),

                    //获取当前月份
                    month: nowDate.getMonth() + 1,

                    //获取当前日期
                    date: nowDate.getDate(),

                }
                this.year = date.year;
            }
        }
    }

</script>
<style scoped lang='less'>
    .footer {
        margin-top: 30px;
        text-align: center;
        padding-bottom: 40px;

        div {
            margin-top: 7px;
            color: #999;
            font-size: 13px;
        }
    }

    .otherfoot {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 50px;
        padding-bottom: 0;

        div {
            color: white;
        }
    }
</style>