<template>
    <div class="head flex" id="chead">
        <div class="bread">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">当前位置</el-breadcrumb-item>
                <el-breadcrumb-item>{{bread1}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{bread2}}</el-breadcrumb-item>
                <!-- <el-breadcrumb-item v-for="item in arr" :key="item">{{item}}</el-breadcrumb-item> -->
                <!-- <el-breadcrumb-item>{{info2}}</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>
        <div class="right flex">
            <span class="caaa dis" style="cursor: text;" v-if="info?.invitation_code">
                <i class="el-icon-s-promotion"></i>
                <span>邀请码:({{info?.invitation_code || code}})</span>
                <span style="margin-left: 10px;" v-show="codeTime">到期时间:{{codeTime}}</span>
            </span>
            <span class="caaa dis" style="cursor: text;" v-if="projectInfo?.invitation_code">
                <i class="el-icon-s-promotion"></i>
                <span>邀请码({{projectInfo?.invitation_code || code}})</span>
                <span style="margin-left: 10px;" v-show="codeTime">到期时间:{{codeTime}}</span>
            </span>
            <el-badge :value="numberinfo.zixun" class="item" v-if="numberinfo.zixun>0">
                <span class="caaa" @click='conChange'>
                    <i class="el-icon-s-comment"></i>
                    在线咨询

                </span>
            </el-badge>
            <span class="caaa" @click='conChange' v-else>
                <i class="el-icon-s-comment"></i>
                在线咨询

            </span>

            <!-- <span class="caaa dis">
                <i class="el-icon-s-check"></i>
                服务与咨询
            </span> -->
            <span class="caaa dis" @click="getIndex">
                <i class="el-icon-s-check"></i>
                回到首页
            </span>
            <!-- <span class="caaa" @click="goPlat">
                <i class="el-icon-s-home"></i>
                平台管理
            </span> -->
            <div class="end flex">
                <div style="color: #74ADFF;text-align: right;">
                    <div style="color: #666">{{info.user_name}}</div>
                    {{info.position_name}}
                </div>
                <img @click="exit" src="@/assets/company.png" />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                info: JSON.parse(sessionStorage.getItem('userInfo')),
                bread1: sessionStorage.getItem('navs'),
                bread2: '',
                info2: '',
                arr: [],
                projectInfo: sessionStorage.getItem('projectInfo'),
                code: "",
                codeTime: "",
                numberinfo: {
                    zixun: 0,
                    kefu: 0
                },
                timer2: null
            }
        },
        watch: {
            $route(val) {
                let tree = JSON.parse(sessionStorage.getItem('forbread'))
                this.bread2 = this.filterNav(tree, val.path)
                this.arr = this.arr.reverse()
            }
        },
        created() {
            this.bread1 = sessionStorage.getItem('navs')
            this.init(JSON.parse(sessionStorage.getItem("userInfo")).user_type)
        },
        mounted() {
            let tree = JSON.parse(sessionStorage.getItem('forbread'))
            this.bread2 = this.filterNav(tree, this.$route.path)
            this.projectInfo = JSON.parse(sessionStorage.getItem('projectInfo'))
            this.$http({
                url: "api/admin.supply.platform/get_user",
                data: {
                    user_id: JSON.parse(sessionStorage.getItem("userInfo")).id,
                },
                done: (res) => {


                    this.info.user_name = res.user_name
                    this.code = res.invitation_code
                    this.codeTime = res.invitation_code_end_time

                },
            });
            this.timer = setInterval(() => {
                this.$http({
                    url: "api/admin.supply.consult/getMessageNum",
                    data: {
                        user_id: JSON.parse(sessionStorage.getItem("userInfo")).id,
                    },
                    done: (res) => {
                        this.numberinfo = res
                        if (this.numberinfo.zixun > 99) {
                            this.numberinfo.zixun = '99+'
                        }
                        if (this.numberinfo.kefu > 99) {
                            this.numberinfo.kefu = '99+'
                        }
                        this.$bus.$emit('numberChange', this.numberinfo)
                    },
                });
            }, 3000)
            this.getRule()

            // console.log("返回数据", this.projectInfo)
        },
        methods: {
            getRule() {
                this.timer2 = setInterval(() => {
                    this.$http({
                        url: "/api/admin.project.manage/getRuleAll",
                        data: {

                        },
                        done: (res) => {
                            let ruleArr = JSON.parse(sessionStorage.getItem("rule"));
                            let ruleArr2 = res
                            let c = ruleArr.length == ruleArr2.length && ruleArr.sort().toString() === ruleArr2.sort().toString();
                            if (!c) {
                                sessionStorage.removeItem('userInfo')
                                sessionStorage.removeItem('projectInfo')
                                sessionStorage.removeItem('token')
                                this.$router.push({ path: '/login' })
                                this.$message.error("权限发生改变请重新登入");
                            }
                        },
                    });
                }, 5000)
            },
            filterNav(system, path, str) { // 筛选相应name
                this.arr = []
                for (let item of system) {
                    if (item.path == this.$route.fullPath) {
                        str = item.name
                        // this.arr.push(str)
                        return str
                    }
                    if (item.children && item.children.length > 0) {

                        let name = this.filterNav(item.children, this.$route.fullPath, str)
                        if (name != '') {
                            // this.arr.push(item.name)
                            return name
                        }
                    }
                }
                return ''
            },
            conChange() {
                let routeData = this.$router.resolve({ path: '/front/leaveMsg', query: { istype: '平台' } });
                window.open(routeData.href, '_blank')
            },
            goPlat() {
                let routeData = this.$router.resolve({ path: '/platMag/login' });
                window.open(routeData.href, '_blank')
                // this.$router.push({ path: '/platMag/login' })
            },
            getIndex() {
                this.$router.push({ path: '/' })
            },
            exit() {
                this.$confirm('确认退出登录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    sessionStorage.removeItem('userInfo')
                    sessionStorage.removeItem('projectInfo')
                    sessionStorage.removeItem('token')
                    this.$router.push({ path: '/login' })

                }).catch(() => {
                });

            },
            init(type) {
                let register_type = JSON.parse(sessionStorage.getItem("userInfo")).register_type;
                if (type == 1) {
                    this.bread1 = '项目总包';
                } else if (type == 2) {
                    this.bread1 = '施工企业';
                } else if (type == 4) {
                    if (register_type == 2) {
                        this.bread1 = '班组长';
                    } else {
                        this.bread1 = '劳务公司';
                    }
                } else if (type == 5) {
                    this.bread1 = '咨询公司';
                } else if (type == 6) {
                    this.bread1 = '其他咨询公司';
                } else if (type == 0) {
                    this.bread1 = '工程项目';
                } else if (type == 3) {
                    this.bread1 = '供应商';
                } else if (type == 7) {
                    this.bread1 = '专家';
                } else if (type == 8) {
                    this.bread1 = '平台';
                }
            }
        },
        destroyed() {
            clearInterval(this.timer)
            clearInterval(this.timer2)
        },
    }
</script>
<style scoped lang="less">
    .caaa {
        cursor: pointer;
        position: relative;
        padding-right: 10px;

        i {
            font-size: 15px;
        }

    }

    .dis {
        margin: 0 20px;
    }

    .end {
        margin-top: -6px;
        margin-left: 40px;

        div {

            margin-right: 13px;
        }

        img {
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }

    .right {
        margin-top: 15px;
    }

    .head {
        background: #FFFFFF;
        box-shadow: 0px 3px 7px 0px rgba(31, 37, 51, 0.05);
        padding: 0px 20px;
        height: 52px;
        font-size: 13px;

        .bread {
            margin-top: 18px
        }
    }

    .flex {
        justify-content: space-between;

    }

    .number {
        // position: absolute;
        // right: 0;
        // top:2px;
        font-size: 12px;
        display: inline-block;
        width: 22px;
        height: 22px;
        // padding: 2px 5px;
        text-align: center;
        // line-height: 22px;
        background-color: #f00;
        color: #fff;
        border-radius: 50%;
    }
</style>