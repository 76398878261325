export default [
  // 施工企业
  {
    path: "/platMag/index",
    name: "platMagIndex",
    component: () => import("@/views/platMag/index"),
  },
  {
    path: "/platMag/info",
    name: "platMagInfo",
    component: () => import("@/views/platMag/info"),
  },
  {
    path: "/platMag/index",
    name: "platMagIndex",
    component: () => import("@/views/platMag/index"),
  },
  {
    path: "/platMag/agency",
    name: "platMagAgency",
    component: () => import("@/views/platMag/agency/index"),
  },
  {
    path: "/platMag/supply/hire",
    name: "platMagHire",
    component: () => import("@/views/platMag/supply/hire"),
  },
  {
    path: "/platMag/supply/laborInfo",
    name: "platMagLaborInfo",
    component: () => import("@/views/platMag/supply/laborInfo"),
  },
  {
    path: "/platMag/supply/material",
    name: "platMagMaterial",
    component: () => import("@/views/platMag/supply/material"),
  },
  {
    path: "/platMag/supply/machine",
    name: "platMagMaterial",
    component: () => import("@/views/platMag/supply/machine"),
  },
  {
    path: "/platMag/supply/labor",
    name: "platMagsupplylabor",
    component: () => import("@/views/platMag/supply/labor"),
  },
  {
    path: "/platMag/companyMag/company",
    name: "platMagCompanyMag",
    component: () => import("@/views/platMag/companyMag/company"),
  },
  {
    path: "/platMag/companyMag/stuff",
    name: "platMagstuff",
    component: () => import("@/views/platMag/companyMag/stuff"),
  },
  {
    path: "/platMag/companyMag/labor",
    name: "platMaglabor",
    component: () => import("@/views/platMag/companyMag/labor"),
  },
  {
    path: "/platMag/companyMag/adviceMag",
    name: "adviceMag",
    component: () => import("@/views/platMag/companyMag/adviceMag"),
  },
  {
    path: "/platMag/companyMag/otherAdviceMag",
    name: "otherAdviceMag",
    component: () => import("@/views/platMag/companyMag/otherAdviceMag"),
  },
  {
    path: "/platMag/consult/infoMag",
    name: "consultinfoMag",
    component: () => import("@/views/platMag/consult/infoMag"),
  },
  {
    path: "/platMag/consult/injection",
    name: "consultinfoMag",
    component: () => import("@/views/platMag/consult/injection"),
  },
  {
    path: "/platMag/consult/takingService",
    name: "consultinfoMag",
    component: () => import("@/views/platMag/consult/takingService"),
  },
  {
    path: "/platMag/consult/service",
    name: "consultinfoMag",
    component: () => import("@/views/platMag/consult/service"),
  },
  {
    path: "/platMag/list",
    name: "platMagList",
    component: () => import("@/views/platMag/list"),
  },
  {
    path: "/platMag/account/plat",
    name: "platMagAccountPlat",
    component: () => import("@/views/platMag/account/plat"),
  },
  {
    path: "/platMag/account/task",
    name: "platMagAccountTask",
    component: () => import("@/views/platMag/account/task"),
  },
  {
    path: "/platMag/account/consTask",
    name: "platMagAccountconsTask",
    component: () => import("@/views/platMag/account/consTask"),
  },
  {
    path: "/platMag/account/expertTask",
    name: "platMagAccountconsTask",
    component: () => import("@/views/platMag/account/expertTask"),
  },
  {
    path: "/platMag/account/indTask",
    name: "platMagAccountconsindTask",
    component: () => import("@/views/platMag/account/indTask"),
  },
  {
    path: "/platMag/account/expire",
    name: "platMagAccountExpire",
    component: () => import("@/views/platMag/account/expire"),
  },
  {
    path: "/platMag/account/frameCommunity",
    name: "platMagFrameCommunity",
    component: () => import("@/views/platMag/account/frameCommunity"),
  },
  {
    path: "/platMag/template",
    name: "platMagtemplate",
    component: () => import("@/views/platMag/template"),
  },
  {
    path: "/platMag/news/map",
    name: "platMagMap",
    component: () => import("@/views/platMag/news/map"),
  },
  {
    path: "/platMag/news/agree",
    name: "platMagAgree",
    component: () => import("@/views/platMag/news/agree"),
  },
  {
    path: "/platMag/news/platform",
    name: "platMagAgree",
    component: () => import("@/views/platMag/news/platform"),
  },
  {
    path: "/platMag/news/commerce",
    name: "platMagAgree",
    component: () => import("@/views/platMag/news/commerce"),
  },
  {
    path: "/platMag/news/advertise",
    name: "platMaadvertise",
    component: () => import("@/views/platMag/news/advertise"),
  },
  {
    path: "/platMag/book",
    name: "platMagbook",
    component: () => import("@/views/platMag/service/book"),
  },
  {
    path: "/platMag/article",
    name: "platMagarticle",
    component: () => import("@/views/platMag/small/article"),
  },
  {
    path: "/platMag/train",
    name: "platMagtrain",
    component: () => import("@/views/platMag/service/train"),
  },
  {
    path: "/platMag/feedback",
    name: "platMagtrain",
    component: () => import("@/views/platMag/service/feedback"),
  },
  {
    path: "/platMag/login",
    name: "platMagLogin",
    component: () => import("@/views/platMag/login"),
  },
  {
    path: "/platMag/labor",
    name: "platMaglabor",
    component: () => import("@/views/platMag/need/labor"),
  },
  {
    path: "/platMag/purchase",
    name: "platMagpurchase",
    component: () => import("@/views/platMag/need/buy"),
  },
  {
    path: "/platMag/news/index",
    name: "platMagnewsindex",
    component: () => import("@/views/platMag/news/index"),
  },
  {
    path: "/platMag/news/about",
    name: "platMagaboutindex",
    component: () => import("@/views/platMag/news/about"),
  },
  {
    path: "/platMag/news/deal",
    name: "platMagaboutdeal",
    component: () => import("@/views/platMag/news/deal"),
  },
  {
    path: "/platMag/keyword",
    name: "keyword",
    component: () => import("@/views/platMag/keyword"),
  },
];
