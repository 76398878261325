<template>
	<el-dialog :title="title" :visible="visible" class="mid-m" :show-close="showClose" :close-on-click-modal="showClose"
		:close-on-press-escape="showClose" append-to-body :before-close="handleClose" :width="diaWidth + 'px'">
		<div>
			<slot name="info">

			</slot>
		</div>

		<el-form :label-width="labelwidth + 'px'" :model="form" ref="form">
			<el-row :gutter="20">
				<el-col :span="item.col" v-for="(item, index) in fields" :key="index">
					<el-form-item :key="item.field" :class="{
              block: inline === true && item.block === true,
              splitline: item.type === 'splitline' && !item.label,
              splitlinehastext: item.type === 'splitline' && !!item.label,
              ffflex: item.btn != undefined && item.btn != '',
            }" :label="item.label" :prop="item.field" :rules="item.rules">
						<el-select :placeholder="item.placeholder || '请输入'+item.label" v-if="item.type === 'select'"
							v-model="form[item.field]" :multiple="item.multiple === true"
							:collapse-tags="item.multiple === true" :filterable="item.filterable === true"
							:disabled="item.disabled === true" :clearable="item.clearable === true"
							@change="(val) => $emit('change', val)">
							<el-option v-for="(option, index) in item.options" :key="index" :value="option.value"
								:label="option.label" :disabled="option.disabled"></el-option>
						</el-select>
						<el-radio-group v-else-if="item.type === 'radio'" v-model="form[item.field]"
							@change="changeRadio($event, item)">
							<el-radio v-for="(option, index) in item.options" :key="index" :label="option.value"
								:disabled="option.disabled">{{ option.label }}</el-radio>
						</el-radio-group>
						<el-checkbox-group v-else-if="item.type === 'checkbox'" v-model="form[item.field]">
							<el-checkbox v-for="(option, index) in item.options" :key="index" :label="option.value"
								:disabled="option.disabled">{{ option.label }}</el-checkbox>
						</el-checkbox-group>
						<el-checkbox v-model="form[item.field]" @change="changeCheck($event, form[item.field])"
							v-else-if="item.type === 'checkboxs'">{{ item.des }}</el-checkbox>
						<el-input clearable v-else-if="item.type === 'input'" v-model="form[item.field]" :style="{
                marginRight:
                  item.btn != undefined && item.btn != '' ? '-1px' : '',
              }" :prefix-icon="item.prefixIcon" :suffix-icon="item.suffixIcon"
							:placeholder="item.placeholder || '请输入'+item.label" :readonly="item.onlyread === true"
							:disabled="item.disabled === true"></el-input>
						<el-input clearable v-else-if="item.type === 'password'" type="password"
							v-model="form[item.field]" :prefix-icon="item.prefixIcon" :suffix-icon="item.suffixIcon"
							:placeholder="item.placeholder" :readonly="item.onlyread === true"
							@keyup.enter.native="$emit('submit')"></el-input>
						<el-input clearable v-else-if="item.type === 'number'" type='number' :style="{
                marginRight:
                  item.btn != undefined && item.btn != '' ? '-1px' : '',
              }" v-model="form[item.field]" :prefix-icon="item.prefixIcon" :suffix-icon="item.suffixIcon"
							:placeholder="item.placeholder || '请输入'+item.label" :readonly="item.onlyread === true"
							@keyup.enter.native="$emit('submit')"></el-input>
						<el-input clearable v-else-if="item.type === 'number2'" type='number' :style="{
								marginRight:
								  item.btn != undefined && item.btn != '' ? '-1px' : '',
							  }" v-model="form[item.field]" :prefix-icon="item.prefixIcon" :suffix-icon="item.suffixIcon"
							:placeholder="item.placeholder || '请输入'+item.label" :readonly="item.onlyread === true"
							@keyup.enter.native="$emit('submit')" @input="changeCheck($event, form[item.field])">
						</el-input>

						<el-input clearable v-else-if="item.type === 'textarea'" type="textarea"
							v-model="form[item.field]" :readonly="item.onlyread === true"></el-input>
						<el-input-number clearable v-else-if="item.type === 'input-number'" v-model="form[item.field]"
							:min="item.min || 1" :max="item.max || 10" :disabled="item.onlyread === true">
						</el-input-number>
						<el-date-picker v-else-if="
                item.type === 'daterange' || item.type === 'datetimerange'
              " v-model="form[item.field]" :type="item.type" range-separator="~" :disabled="item.disabled === true"
							:readonly="item.onlyread === true" :editable="item.editable === true"
							:clearable="item.clearable === true" :format="
                'yyyy-MM-dd' +
                (item.type === 'datetimerange' ? ' HH:mm:ss' : '')
              " :value-format="
                'yyyy-MM-dd' +
                (item.type === 'datetimerange' ? ' HH:mm:ss' : '')
              " :default-time="['00:00:00', '23:59:59']"></el-date-picker>
						<el-date-picker :default-value="getCurrentData" clearable v-else-if="item.type === 'date'"
							@change="changePick($event, item)" :picker-options="pickerOptions3" type="date"
							v-model="form[item.field]" :disabled="item.disabled === true"
							:readonly="item.onlyread === true" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
							placeholder="选择日期">
						</el-date-picker>
						<el-date-picker clearable v-else-if="item.type === 'dateA'" @change="changePick($event, item)"
							type="date" v-model="form[item.field]" :disabled="item.disabled === true"
							:readonly="item.onlyread === true" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
							placeholder="选择日期" :picker-options="pickerOptions1">
						</el-date-picker>
						<el-date-picker clearable v-else-if="item.type === 'dateB'" @change="changePick($event, item)"
							type="date" v-model="form[item.field]" :disabled="item.disabled === true"
							:readonly="item.onlyread === true" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
							placeholder="选择日期" :picker-options="pickerOptions2">
						</el-date-picker>
						<el-date-picker clearable :picker-options="pickerOptions3" @change="changePick($event, item)"
							v-else-if="item.type === 'datetime'" type="datetime" style="width: 176px !important"
							v-model="form[item.field]" :disabled="item.disabled === true"
							:readonly="item.onlyread === true" format="yyyy-MM-dd HH:mm:ss"
							value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
						<el-time-select v-else-if="item.type === 'time'" v-model="form[item.field]"
							:disabled="item.disabled === true" :readonly="item.onlyread === true"
							value-format="HH:mm:ss" placeholder="选择时间"></el-time-select>
						<el-slider v-else-if="item.type === 'slider'" v-model="form[item.field]"
							:disabled="item.onlyread === true">
							{{ form[item.field] }}
						</el-slider>
						<span class="unit" v-if="item.btn">{{ item.btn }}</span>
						<selectfilter :placeholder="'请输入或选择'+item.label" :api="item.api" :item="item"
							:val="item.defaultValue" :info="item.info" :options="item.options" @data="getRes"
							:close="close" :disabled2="item.disabled" :defaultvalue="form[item.field]"
							:request-data="item.request" v-else-if="item.type === 'filter'">
						</selectfilter>
						<selectfilters :placeholder="'请输入或选择'+item.label" :typeNum="item.typeNum" :api="item.api"
							:item="item" :val="item.defaultValue" :info="item.info" :options="item.options"
							@data="getRes" :close="close" :disabled2="item.disabled" :defaultvalue="form[item.field]"
							:request-data="item.request" v-else-if="item.type === 'filter2'">
						</selectfilters>
						<uploadfile-more ref="uploadsdom2" :close="close" @fileurl="getUrl" :list="form[item.field]"
							@fileDel="delFile" :item="item" v-else-if="item.type === 'file' && item.type3 === 'molist'">
						</uploadfile-more>
						<uploads ref="uploadsdom3" :close="close" @fileurl="getUrlSingle" :path="form[item.field]"
							@fileDel="delFileSingle" :item="item"
							v-else-if="item.type === 'file' && item.type2 !== 'more'"></uploads>
						<uploadfile-mul ref="uploadsdom2" :close="close" @fileurl="getUrl" :list="form[item.field]"
							@fileDel="delFile" :item="item" v-else-if="item.type === 'file' && item.type2 === 'more'">
						</uploadfile-mul>
						<!-- <UploadImg v-else-if="item.type === 'UploadImg'"></UploadImg> -->
						<uploaders ref="uploadsdom" :close="close" @fileurl="getUrlSingle" :list="form[item.field]"
							@fileDel="delFile" :item="item" :showUploadBtn="true"
							v-else-if="item.type === 'ortherFile'"></uploaders>
						<addresss :item="item" @getadd="getAddress" :list="form.address" :isDisabled="item.disabled"
							v-else-if="item.type === 'address'"></addresss>
						<!-- 可复用的作用域插槽用于补充当前未包含的类型 -->
						<!-- 插槽定义：<slot :name=插槽名 v-bind:父组件中接收被传递属性的属性名=子组件中要传递的属性的属性名> -->
						<!-- 插槽使用：<template v-slot:插槽名="插槽prop的对象名"></template> 或 <template v-slot:插槽名="{父组件中接收被传递属性的属性名}"></template> -->
						<slot v-else-if="item.type === 'slot'" :item="item" :name="item.field" v-bind:form="form">
						</slot>
					</el-form-item>
				</el-col>
			</el-row>
			<div :style="{ textAlign: btnstyle }" class="mt-10">
				<span :style="{ width: labelwidth + 'px' }" style="width: 100px; display: inline-block"></span>
				<el-button v-for="item in submitbtn" :key="item" type="primary" @click="save" v-loading="loading">
					{{ item }}
				</el-button>
				<span v-show="isdesc" style="position: relative;left:320px;">需修改内容？ <span style="color:#66b1ff ;"
						@click.stop="toTell">请联系客服</span> </span>
			</div>
		</el-form>
	</el-dialog>
</template>
<script>
	import addresss from "./address.vue";
	import uploaders from "./uploaders.vue";
	import uploadfileMul from "./uploadFileMul.vue";
	import uploadfileMore from "./uploadFileMore.vue";
	import selectfilter from "./common/selectfcom";
	import selectfilters from "./common/selectfcom2";

	// import UploadImg from "./UploadImg.vue";


	export default {
		props: {
			showClose: {
				type: Boolean,
				default: true,
			},
			submissionType: {
				type: Boolean,
				default: false,
			},
			submitbtn: {
				type: Array,
				default: () => [],
			},
			labelwidth: {
				type: String,
				default: "113",
			},
			diaWidth: {
				type: String,
				default: "700",
			},
			width: {
				type: Number,
				default: 1,
			},
			inline: {
				type: Boolean,
				default: false,
			},
			visible: {
				type: Boolean,
				default: false,
			},
			title: {
				type: String,
				default: "",
			},
			btnstyle: {
				type: String,
				default: "",
			},
			editid: {
				type: String,
				default: "",
			},
			isfeedback: {
				type: Boolean,
				default: false,
			},
			issetvalue: {
				type: Boolean,
				default: false,
			},
			isdesc: {
				type: Boolean,
				default: false,
			},
			dataId: [String, Number],
			fields: {
				type: Array,
				default: () => [],
				// 示例 [{ field: 'name', label: '名称', type: 'input', ?defaultValue: '', ?rules: [], ?onlyread }]
				// { field: 'type', label: '类型', type: 'select', ?defaultValue: '', options: [{label, value, ?disabled}], ?rules: [] }
				// { field: 'enable', label: '是否启用', type: 'radio', ?defaultValue: '', options: [], ?rules: [] }
				// { field: 'industry', label: '所属行业', type: 'checkbox', ?defaultValue: [], options: [], ?rules: [] }
			},
			dataSource: Object,
			getInfoApi: Function,
			saveapi: {
				type: String,
				default: "",
			},
			updateApi: Function,
		},
		filters: {
			NumFormat(str) {
				console.log("过来的数据", str)
			}
		},
		data() {
			return {
				form: {},
				rules: {},
				saving: false,
				loading: false,
				close: false,
				getCurrentData: new Date(),
				endTime: JSON.parse(sessionStorage.getItem('projectInfo'))?.finish_date_totlal,
				startTime: JSON.parse(sessionStorage.getItem('projectInfo'))?.ment_date,
				pickerOptions1: {
					disabledDate: (time) => {
						if (this.startTime) {
							return (
								time.getTime() <= new Date(this.startTime).getTime() - 8.64e7 || time.getTime() >=
								new Date(this.endTime).getTime()
							);
						} else {
							return ''
						}
					}
				},
				pickerOptions2: {
					disabledDate: (time) => {
						if (this.startTime) {
							return (
								time.getTime() <= new Date(this.startTime).getTime() - 8.64e7 || time.getTime() >=
								new Date(this.endTime).getTime()
							);
						} else {
							return ''
						}
					}
				},

				pickerOptions3: {
					disabledDate: (time) => {
						if (this.startTime) {
							return (
								time.getTime() <= new Date(this.startTime).getTime() - 8.64e7 || time.getTime() >=
								new Date(this.endTime).getTime()
							);
						} else {
							return ''
						}

					}
				}
			};
		},
		components: {
			uploadfileMul,
			uploadfileMore,
			selectfilter,
			addresss,
			uploaders,
			selectfilters,


		},
		computed() {



		},
		watch: {
			fields(val) {

				if (this.issetvalue) {
					this.init();
				}
				// this.init()
			},
			visible(val) {
				if (!val) {
					this.form = {};
					this.init();
					this.$refs.form.resetFields();
				}
				// if (this.form.id == undefined && this.isfeedback) {
				//   setTimeout(() => {
				//     if (!val) {
				//       this.resetForm()
				//     }
				//     this.$refs.form.resetFields()
				//   }, 50);
				//   // this.$refs.form.resetFields()
				// } else if (this.form.id == '' && this.isfeedback) { //修改关闭
				//   if (!val) {
				//     this.form = {}
				//     this.$refs.form.resetFields()
				//   }

				// }
			},
		},
		mounted() {
			this.endTime = JSON.parse(sessionStorage.getItem('projectInfo'))?.finish_date_totlal
			this.startTime = JSON.parse(sessionStorage.getItem('projectInfo'))?.ment_date
			this.pickerOptions1 = {
				disabledDate: (time) => {
					if (this.startTime) {
						return (
							time.getTime() <= new Date(this.startTime).getTime() - 8.64e7 || time.getTime() >=
							new Date(this.endTime).getTime()
						);
					} else {
						return ''
					}
				}
			},
				this.pickerOptions2 = {
					disabledDate: (time) => {
						if (this.startTime) {
							return (
								time.getTime() <= new Date(this.startTime).getTime() - 8.64e7 || time.getTime() >=
								new Date(this.endTime).getTime()
							);
						} else {
							return ''
						}

					}
				},
				this.pickerOptions3 = {
					disabledDate: (time) => {
						if (this.startTime) {
							return (
								time.getTime() <= new Date(this.startTime).getTime() - 8.64e7 || time.getTime() >=
								new Date(this.endTime).getTime()
							);
						} else {
							return ''
						}

					}
				}
			if (this.fields) {
				// 根据字段是否是仅读初始化form和formFields
				this.init();
			} else {
				return [];
			}
		},
		methods: {

			getNmer(number) {
				number = number.replace(/[^0-9.]/g, '')
				return number

			},
			toTell() {
				// this.$router.push({
				// 	path: "/common/adviser"
				// });
				let obj = {
					type: 0,
					advice_id: -1,
				}
				let routeData = this.$router.resolve({ path: '/front/leaveMsg', query: obj });
				window.open(routeData.href, '_blank')
			},
			init() {
				this.fields.forEach((item) => {
					if (item.field) {
						let value = "";
						if (item.defaultValue !== undefined && item.defaultValue !== null) {
							value = item.defaultValue;
						} else if (item.type === "radio") {
							value = "";
						} else if (item.type === "checkbox") {
							value = [];
						} else if (item.type === "upload") {
							value = [];
						} else if (item.type === "select" && item.multiple) {
							value = [];
						} else if (item.type === "file" && !item.type2) {
							value = "";
						} else if (item.type2 === "more") {
							value = [];
						} else if (item.type === "address") {
							value = [];
						}
						this.$set(this.form, item.field, value);
						// item.rules && this.$set(this.rules, item.field, item.rules);
					}
				});
			},
			getRes(e) {

				// this.form[e.names + "_name"] = e.label;
				// this.form[e.names + "_id"] = e.id;
				this.form[e.names] = e.label;
			},
			changeCheck(e, val) {

				this.$emit("check", this.form);
			},
			changePick(e, item) {
				let obj = {
					...item,
					value: e
				}

				this.$emit("changePick", obj);

			},
			getAddress(e) {

				this.form["addr_str"] = e.data;
				this.form[e.name] = e.addr;
			},
			changeRadio(e, name) {
				let obj = {
					value: e,
					name: name.field,
				};
				this.$emit("radio", obj);
			},
			delFileSingle(item) {
				this.form[item.name] = "";
			},
			delFile(item) {
				this.form[item.name].splice(item.index, 1);
			},
			getUrlSingle(e) {
				this.form[e.name] = e.res;
				if (this.form["file_title"]) this.form["file_title"] = [e.title];

			},
			getUrl(e) {
				if (this.form[e.name] == "" || this.form[e.name] == undefined) {
					this.form[e.name] = [];
					this.form["file_title"] = [];
				}

				this.form[e.name].push(e.res);
				if (this.form["file_title"]) this.form["file_title"].push(e.title);
			},
			resetForm() {
				for (let i in this.form) {
					if (typeof this.form[i] == "string") {
						this.form[i] = "";
					} else if (Array.isArray(this.form[i])) {
						this.form[i] = [];
					} else if (typeof this.form[i] == "number") {
						this.form[i] = "";
					} else {
						this.form[i] = "";
					}
				}

			},
			handleClose() {
				// this.resetForm()
				this.form = {};
				this.init();
				this.$refs.form.resetFields();
				this.close = false;
				this.loading = false;
				setTimeout(() => {
					this.close = true;
					this.$emit("close", true);
				}, 50);
			},
			save() {
				let nav = sessionStorage.getItem("navs");
				let bool1 = true;
				let bool2 = true;
				let bool3 = true;
				if (nav == "项目" || nav.indexOf("咨询") > -1) {
					bool1 = true;
					bool2 = false;
					bool3 = false;
				} else {
					bool1 = false;
					bool2 = true;
					bool3 = false;
				}
				if (nav.indexOf("咨询") > -1) {
					bool2 = true;
				}
				if (nav.indexOf("平台") > -1) {
					bool1 = false;
					bool2 = false;
					bool3 = false;
				}
				if (nav == "个人") {
					bool1 = false;
					bool2 = false;
					bool3 = true;
				}
				if (!this.isfeedback) {
					// 在各自页面调取
					this.loading = true;

					setTimeout(() => {
						this.loading = false;
					}, 1000);
				}
				if (this.$refs.uploadsdom && this.$refs.uploadsdom[0].loading) {
					this.$message.success("正在上传数据,请稍等");
					return
				}
				if (this.$refs.uploadsdom2 && this.$refs.uploadsdom2[0].loading) {
					this.$message.success("正在上传数据,请稍等");
					return
				}
				if (this.$refs.uploadsdom3 && this.$refs.uploadsdom3[0].loading) {
					this.$message.success("正在上传数据,请稍等");
					return
				}


				this.$refs.form.validate((r) => {
					if (r) {
						this.close = false;

						if (this.isfeedback) {
							// 在各自页面调用接口
							this.$emit("feed", this.form);
							this.handleClose();
							return;
						}
						this.saving = true;
						if (this.form.id) {
							this.$http({
								url: this.saveapi,
								data: this.form,
								add: bool1,
								addc: bool2,
								addp: bool3,
								done: (res) => {
									this.close = true;
									this.form = {};
									this.$emit("finish", true);
									this.$emit("finishres", res);
									this.$message.success("修改成功");

									this.$refs.form.resetFields();
									this.loading = false;
								},
							});
						} else {
							if (this.submissionType) {
								this.$emit("submissionTypeFn", this.form);
								return
							}
							this.$http({
								url: this.saveapi,
								data: this.form,
								add: bool1, // 判断加不加project_id
								addc: bool2, // 判断加不加prise_id
								done: (res) => {
									this.close = true;
									this.form = {};
									this.resetForm();
									this.$emit("finish", true);
									this.$emit("finishres", res);
									this.$message.success("新增成功");
									this.$refs.form.resetFields();
									this.loading = false;
								},
							});
							// this.saveapi(Object.assign({}, this.form))
							//   .then(res => {
							//     this.saving = false;
							//     if (res) {
							//       this.$emit('finish', true)
							//       this.$message.success("新增成功");
							//       this.$emit("dataChange");
							//     } else {
							//       this.$message.error("新增失败");
							//     }
							//   })
							//   .catch(() => (this.saving = false));
						}
					}
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	/deep/ .ffflex .el-form-item__content {
		display: flex;
	}

	.unit {
		display: inline-block;
		border-left: 1px solid #dcdfe6;
		line-height: 37px;
		min-width: 37px;
		text-align: center;
		box-sizing: border-box;
		margin-top: 3px;
		line-height: 37px;
		position: absolute;
		right: 0;
		bottom: 1px;
		top: 1px;
	}

	/deep/ .el-date-editor.el-input,
	/deep/.el-date-editor.el-input__inner {
		// width: 220px;
	}

	/deep/ .el-cascader {
		width: 100%;
	}

	/deep/ .el-date-editor.el-input {
		width: 100% !important;
	}

	/deep/ .el-radio {
		margin-right: 10px;
	}

	/deep/ .el-form-item__label {
		font-size: 13px;
	}

	/deep/ .el-form-item {
		font-size: 12px;
		margin-bottom: 15px;
	}

	/deep/ .el-input--suffix .el-input__inner {
		padding-right: 14px;
	}
</style>